
import React from 'react';
import JobResumeButton from './JobResumeButton';
import JobApplyButton from './JobApplyButton';

class JobApplyMiddle extends React.Component {

    render() {
        var { is_expired, location_continent, location_country, location_city, title, company, url, application_template, apply_email, featured } = this.props.job;

        // Location
       /*   var loc = "";

        if (location_city) {
            loc = location_city;
        } else if (location_country) {
            if (location_country == "United Kingdom") {
                loc = "UK";
            } else {
                loc = location_country;
            }
        } else if (location_continent) {
            loc = location_continent;
        }


      let content = "";
        if (is_expired) {
            content = <div className="job-tile-expired">Unfortunately, this role is now closed</div>;
        } else {
            content = <a href={url + (!application_template && !apply_email ? "/apply" : "")} onMouseDown={(e) => this.props.onClick(e)} target="_blank" rel="noopener noreferrer nofollow" className="btn btn-success cta btn-huge" >
                Apply now
                {loc ? <div className="row apply-location">{loc} based applicants only</div> : ""}
            </a>

        }*/

        return (
            <div className="row job-apply-middle">
                {<JobApplyButton {...this.props} />}
                {/*!featured && company && !company.featured ? <JobResumeButton/> : ""*/}

            </div>

        );
    }
}

export default JobApplyMiddle;