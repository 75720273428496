import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { IsLoggedIn } from '../../utils/user';
import { faBriefcase, faHandSparkles, faMapLocation, faMapLocationDot, faMapMarker, faMapMarkerAlt, faPlusCircle, faPlusSquare, faRocket, faStar, faUserCheck, faUserPlus, faWandSparkles } from '@fortawesome/free-solid-svg-icons';
import { faThinkPeaks } from '@fortawesome/free-brands-svg-icons';

class MenuMobile extends React.Component {
    constructor() {
        super();
        this.menuWrapperRef = React.createRef();
        this.state = {
        };
    }

    goToTop() {
        document.body.scrollTop = 0
    }


    render() {
        var path = this.props.location.pathname.trim()
        var spl = path.split("/");
        var page = spl[spl.length - 1];

        let { user } = this.props;
        //console.log("useruser", user)

        const mobileItems = [
            { key: "home", url: "/", icon: "home", text: "Home" },
            { key: "remote-jobs", url: "/jobs", icon: "laptop-code", text: "Jobs", separator: "large" }, 
            { key: "companies", url: "/companies", icon: "building", text: "Companies" },
            { key: "countries", url: "/countries", icon: faMapMarkerAlt, text: "Countries" },
            { key: "pros-and-cons", url: "/pros-and-cons", icon: faThinkPeaks, text: "Pros and Cons" }
         //   { key: "advice", url: "/advice", icon: "clipboard-check", text: "Advice" },

        ];

        if (IsLoggedIn(user)) {

            mobileItems.push({ key: "add-company", url: "/add-company", icon: "plus", text: "Submit a company" });
            mobileItems.push({ key: "profile-edit", url: "/profile/edit", icon: "user-alt", text: "Edit profile", separator: "large" });

            if(user && user.response && user.response.company_id){
                mobileItems.push({ key: "edit-company", url: "/dashboard/company", icon: faBriefcase, text: "Edit company profile", separator: "large" });
                mobileItems.push({ key: "company-badge", url: "/dashboard/company/widget", icon: faStar, text: "View company score" });
            }


            mobileItems.push({ key: "post-a-job", url: "/post-a-job", icon: faPlusSquare, text: "Post a job"});
                
            if (user && user.response && user.response.role === "company") {
                mobileItems.push({ key: "posted-jobs", url: "/dashboard/jobs", icon: faRocket, text: "My jobs" });
            }



    
            mobileItems.push({ key: "logout", url: "/logout", icon: "sign-out-alt", text: "Logout", separator: "large" });
        } else {
            
            mobileItems.push({ key: "post-a-job", url: "/post-a-job", text: "Post a job", icon: faPlusSquare, separator: "large" });
            mobileItems.push({ key: "add-company", url: "/add-company", icon: "plus", text: "Submit a company" });
            mobileItems.push({ key: "login", url: "/login", icon: faUserCheck, text: "Login", separator: "large" });
            mobileItems.push({ key: "sign-up", url: "/sign-up", icon: faUserPlus, text: "Sign up" });
        }


        return (

            < ul className="nav navbar-nav tablet-menu padding-top padding-bottom" onClick={() => this.props.onToggleMobile()}>
                {
                    mobileItems.map((item) => (
                        <li key={item.key} className={item.separator ? `separator ${item.separator}` : ''}>
                            <Link
                                to={item.url}
                                onClick={item.external ? null : () => this.goToTop()}
                                target={item.external ? "_blank" : "_self"}
                                rel={item.external ? "noopener noreferrer nofollow" : null}
                                className={(item.className ? item.className : (`btn btn-ghost btn-xl`)) + " " + (path === item.url ? ' active' : '')}
                            >
                                <FontAwesomeIcon icon={item.icon} /> {item.text}
                            </Link>
                        </li>
                    ))
                }

            </ul >

        );
    }
}

export default withRouter(MenuMobile);




