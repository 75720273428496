import React from 'react';
import { Helmet } from 'react-helmet';


class HelmetMeta extends React.Component {
    constructor() {
        super();
        this.state = {
        };
    }

    render() {

        let { title, desc, img, url, date, noIndex, canonical } = this.props;

        return (
            <Helmet>
                <title>{title}</title>

                <meta name="title" content={title} />
                <meta name="description" content={desc} />

                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta property="og:image" content={img} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={url} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={"@4dayweekio"} />
                <meta property="twitter:title" content={title} />
                <meta property="twitter:description" content={desc} />
                <meta property="twitter:image" content={img} />

                {noIndex && noIndex === true ? <meta name="robots" content="noindex"/> : ""}

                <link rel="canonical" href={canonical && canonical != "" ? canonical : url} />

                {date && (  <meta name="publish_date" property="og:publish_date" content={date} />)}
                {date && ( <meta property="article:published_time" content={date.split("T")[0]} />)}
            </Helmet>

        );
    }
}

export default HelmetMeta;




