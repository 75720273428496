import React from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApplicationForm from './ApplicationForm';
import ApplicationFormFail from './ApplicationFormFail';
import ApplicationFormSuccessEmail from './ApplicationFormSuccessEmail';
import ApplicationFormSuccessRedirect from './ApplicationFormSuccessRedirect';
import * as itemActions from '../../store/item/actions';
import { connect } from 'react-redux';
import posthog from 'posthog-js';
import * as Fathom from 'fathom-client';

class ApplicationModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSubmitted: false,
            isValid: false,
        };
    }

    // ChatGPT wrote this
    isSubmissionValid(values) {
        let { application_template } = this.props.job;

        if (!application_template) return true;

        let boolQ = this.validateBooleanQualifiers(values, application_template.boolean_qualifiers);
        let selectQ = this.validateSelectQualifiers(values, application_template.select_qualifiers);
        let numQ = this.validateNumQualifiers(values, application_template.num_qualifiers);
        return boolQ && selectQ && numQ;
    }

    validateBooleanQualifiers(values, booleanQualifiers) {
        if (!booleanQualifiers) return true;
        const validQualifiers = booleanQualifiers.filter(q => values[q.id] === q.accepted_value);
        return validQualifiers.length === booleanQualifiers.length;
    }

    validateSelectQualifiers(values, selectQualifiers) {
        if (!selectQualifiers) return true;
        const validQualifiers = selectQualifiers.filter(q => q.accepted_values.some(v => v.value === values[q.id]));
        return validQualifiers.length === selectQualifiers.length;
    }

    validateNumQualifiers(values, numQualifiers) {
        if (!numQualifiers) return true;
        const validQualifiers = numQualifiers.filter(q => {
            return !values[q.id] ? true
                : q.accepted_type === ">" ? values[q.id] > q.accepted_value
                    : values[q.id] < q.accepted_value;
        });
        return validQualifiers.length === numQualifiers.length;
    }

    onSubmit(v) {
        console.log("Submitting apply form");
        console.log(v);

        let { job, position, user } = this.props;
        let isSuccess = this.isSubmissionValid(v);

        let submit = Object.assign({}, v);
        submit['is_success'] = isSuccess;
        submit['job_id'] = job.id_str;
        submit['job'] = job;

        // Add optional
        if (job.application_template && job.application_template.fields) {
            submit['optional'] = {};
            for (var i = 0; i < job.application_template.fields.length; i++) {
                let cur = job.application_template.fields[i];
                submit['optional'][cur.label] = v[cur.id];
            }
        }

        // Submit application
        this.props.newApplication(submit);

        // Critique
        if (v.resume_upload && v.resume_upload.length > 0 && v.get_resume_review) {
            let crit = {
                email: v.email,
                resume_upload: v.resume_upload,
            }
            this.props.newCritique(crit);
        }


        // Logging
        this.setState({ isSubmitted: true, isValid: isSuccess });

        if (isSuccess) {
            let clickData = { 'page': position, 'action': 'apply_job', 'item_id': job.id_str, 'user_id': user.response.id_str };
            this.props.newClick(clickData);
            Fathom.trackEvent('Apply success (internal)');
            posthog.capture('Apply success (internal)');
        } else {
            Fathom.trackEvent('Apply fail (internal)');
            posthog.capture('Apply fail (internal)');

        }

    }

    componentDidMount(){
       /* console.log("Mounting application modal");
        let { job } = this.props;

        // 1. Email application = always show modal
        // 2. Redirect application
        // - if has qualifiers or fields = show modal
        // - if no qualifiers or fields = redirect
        if(job.apply_email){
            return;
        } else {

        }

*/
    }

    render() {

        var { job } = this.props;
        let { isSubmitted, isValid } = this.state;

        let content = "";

        if (isSubmitted) {
            if (!isValid) {
                content = <ApplicationFormFail {...this.props} onSubmit={(e) => this.onSubmit(e)} />
            } else if (job.apply_email) {
                content = <ApplicationFormSuccessEmail {...this.props} />
            } else {
                content = <ApplicationFormSuccessRedirect  {...this.props} />
            }
        } else {
            content = <ApplicationForm {...this.props} onSubmit={(e) => this.onSubmit(e)} />
        }

        return (
            <div className='application-form'>


                <div className={"modal show"} tabIndex="-1" role="dialog">
                    <div className="modal-dialog  modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Apply: {job.title} <span className="company-name">@ {job.company_name}</span></h5>
                                <button type="button" className="btn btn-dark icon-only btn-lg close" data-dismiss="modal" aria-label="Close" onClick={(e) => this.props.onClose(e)}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>

                            {content}

                        </div>

                    </div>
                </div>


            </div >
        );
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.item.user,
    };
};



const mapDispatchToProps = (dispatch, ownProps) => ({
    newClick: (data) => {
        dispatch(itemActions.newItem("clicks", data));
    },
    newApplication: (data) => {
        dispatch(itemActions.newItem("applications", data));
    },

    newCritique: (data) => {
        dispatch(itemActions.newItem("critiques", data, "critique"));
    },
});

ApplicationModal = connect(mapStateToProps, mapDispatchToProps)(ApplicationModal);
export default ApplicationModal;