import React from 'react';

class JobApplyButton extends React.Component {

    render() {
        let { job } = this.props;
        let { is_expired, location_continent, location_country, location_city, url, application_template, apply_email, apply_url } = job;

        // Location
        let locText = "";

        if (location_city) {
            locText = location_city;
        } else if (location_country) {
            locText = location_country == "United Kingdom" ? "UK" : location_country;
        } else if (location_continent) {
            locText = location_continent;
        }

        let loc = locText ? <div className="row apply-location">{locText} only</div> : "";

        // Redirect button (default)

        let applyUrl = url ? url + "/apply" : apply_url ? apply_url : "#";
        let buttonA = <a href={applyUrl} onMouseDown={(e) => this.props.onClick(e)} target="_blank" rel="noopener noreferrer nofollow" className="btn btn-success cta btn-huge" >
            Apply
            {loc}
        </a>;


        // Native button (fill form)
        let buttonButton = <button onMouseDown={(e) => this.props.onClick(e)} className="btn btn-success cta btn-huge" >
            Apply
            {loc}
        </button>;

        // Expired job
        let expiredJob = <div className="apply-expired">Unfortunately, this role is now closed</div>;

        // Button
        let content = "";
        if (is_expired) {
            // 1. Job closed
            content = expiredJob;
        } else if ((application_template || apply_email)) {
            content = buttonButton;
        } else {
            // 4. Redirect application
            content = buttonA;
        }


        return (<div className="job-apply-button">
            {content}
        </div>);
    }
}

export default JobApplyButton;