import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import JobFiltering from '../components/filters/JobFiltering';
import HelmetMeta from '../components/global/HelmetMeta';
import JobsList from '../components/list/JobsList';
import Loader from '../components/misc/Loader';
import SubscriberInlineWrapper from '../components/subscriber-form/SubscriberInlineWrapper';
import SubscriberModalWrapper from '../components/subscriber-form/SubscriberModalWrapper';
import * as itemActions from '../store/item/actions';
import * as listActions from '../store/list/actions';
import * as listReducer from '../store/list/reducer';
import * as vars from '../store/vars';
import { GenerateLookups, LEVELS_LOOKUP, LOCATIONS_LOOKUP, REDUCED_HOURS_LOOKUP, RENAME_SKILLS } from '../utils/filters';
import { ToTitleCase, Unslug } from '../utils/format';
import * as URLTools from './../utils/url';

import { NotificationManager } from 'react-notifications';

class Jobs extends React.Component {

	/* SSR start */
	static page = "jobs"
	static id = "list"
	static numJobsToLoad = 15 // Batch size of query

	static lookups = GenerateLookups({
		location: LOCATIONS_LOOKUP,
		reduced_hour: REDUCED_HOURS_LOOKUP,
		//remote_option: REMOTE_OPTIONS_LOOKUP,
		level: LEVELS_LOOKUP,
	});

	constructor(props) {
		super(props);
		this.jobsListRef = React.createRef();
		this.handleScroll = this.handleScroll.bind(this);
		this.state = {
			url: "",
			numJobs: 0, // Total number of jobs we want on the page at any one time
			hasFetchedAll: false,
		}
	}

	// First load - SSR
	static loadDataSSR(url) {
		var curParams = URLTools.GetQueryParams(url, Jobs.lookups, "filter");
		curParams.limit = Jobs.numJobsToLoad;
		return listActions.fetchItemsSSR(Jobs.page, Jobs.id, curParams);
	}

	static parseDataSSR(data) {
		var s = vars.getDefaultState();
		s.list = listReducer.receiveListItems(Jobs.page + "_" + Jobs.id, s.list, {
			type: listActions.RECEIVE_LIST_ITEMS,
			page: Jobs.page,
			id: Jobs.id,
			//	params: Jobs.params, todofix
			response: data
		});
		return s;
	}
	/* SSR end */


	componentDidMount() {

		if (typeof window !== 'undefined') {
			window.addEventListener('scroll', this.handleScroll, true); // Add event listener
		}

		var { location } = this.props;
		let shouldLoad = !this.props.data.init || this.props.history.action == "PUSH";

		if (shouldLoad) {
			this.reset();
			this.getJobs(this.getUrl(location));
		}

		// Critique
		if (localStorage.getItem("critique_id")) {
			this.props.getCritique({ id: localStorage.getItem("critique_id") });
		}

		if (!location || !location.search) {
			return;
		}

		// Notifications + redirects
		let signupJobSlug = localStorage.getItem("signup_job_slug"); // Redirect if they logged in or signed up from a job

		if (location.search.indexOf("welcome=true") != -1) {
			if (signupJobSlug) {
				// Redirect
				localStorage.removeItem("signup_job_slug");
				this.props.history.push("/remote-job/" + signupJobSlug + "?startedapplication=true");
			} else {
				NotificationManager.info('', 'Successfully logged in', 6000);
			}
		} else if (location.search.indexOf("signupcomplete=true") != -1) {
			NotificationManager.success('', 'Signup completed', 6000);
		} else if (location.search.indexOf("profileupdated=true") != -1) {
			NotificationManager.success('', 'Profile updated', 6000);
		}
	}


	componentDidUpdate(prevProps, prevState) {

		const { location: lastLocation, data: lastData } = prevProps;
		const { location: nextLocation, data: newData } = this.props;

		if (!newData.init) {
			this.getJobs(this.getUrl(nextLocation));
		} else if (URLTools.HasPageChanged(lastLocation, nextLocation)) {
			this.reset();
		} else if (lastData && newData && lastData.loading && !newData.loading) {
			if (lastData.itemIds.length == newData.itemIds.length && newData.itemIds.length != Jobs.numJobsToLoad) {
				this.setState({ hasFetchedAll: true });
			}
		}
	}

	getUrl(location) {
		return location.pathname + location.search;
	}


	getJobs(newUrl, newNumJobs = Jobs.numJobsToLoad) {
		let { url: oldUrl, numJobs: oldNumJobs, hasFetchedAll } = this.state;
		let { data } = this.props;

		if (hasFetchedAll) {
			return;
		} else if (data.loading) {
			return;
		} else if (oldUrl == newUrl && newNumJobs == oldNumJobs) {
			return;
		}

		var curParams = URLTools.GetQueryParams(newUrl, Jobs.lookups, "filter");

		if (newNumJobs > Jobs.numJobsToLoad) {
			curParams.offset = newNumJobs - Jobs.numJobsToLoad;
		}
		curParams.limit = Jobs.numJobsToLoad;

		this.props.loadData(curParams);

		this.setState({
			url: newUrl,
			numJobs: newNumJobs,

		})
	}

	reset() {
		this.props.resetItems();

		this.setState({
			url: "",
			numJobs: 0,
			hasFetchedAll: false,
		})
	}

	// Fetch more jobs if near end
	handleScroll() {
		if (this.props.data.loading) {
			return;
		}

		const jobsListDiv = this.jobsListRef.current;
		const rect = jobsListDiv.getBoundingClientRect();

		const isBottom = (rect.bottom - window.innerHeight) < 2500;

	//	console.log("Scrolling...", isBottom, rect.bottom, window.innerHeight, rect.bottom - window.innerHeight)


		if (isBottom) {
			this.fetchMoreJobs();
		}

	}

	fetchMoreJobs() {
		let { location } = this.props;
		this.getJobs(this.getUrl(location), this.state.numJobs + Jobs.numJobsToLoad);
	}

	componentWillUnmount() {
		console.log("Unmounting...", this.props);
		window.removeEventListener('scroll', this.handleScroll, true);
		let { history } = this.props;
		if (history.action == "PUSH" && ((history.location.pathname.indexOf("/remote-job/") == -1) && (history.location.pathname.indexOf("/company/") == -1))) {
			//	console.log("Resetting items");
			this.reset();
		}
	}


	render() {
		var { data, match, location: locationUrl, jobs_filters } = this.props;
		let { numJobs, hasFetchedAll } = this.state;

		let fullUrl = locationUrl.pathname + locationUrl.search;

		var curParams = URLTools.GetQueryParams(fullUrl, Jobs.lookups, "filter")


		let { remote_option, reduced_hour, filter, location, level } = curParams;

		// Remote
		let remoteStr = remote_option == "hybrid" ? "Hybrid Remote" : remote_option == "remote" ? "Remote" : remote_option == "onsite" ? "On-site" : "";



		// Reduced hours
		let reducedHoursStrPre = "";
		let reducedHoursStrPost = ""

		if (reduced_hour) {
			if (reduced_hour == "4-day-week") {
				reducedHoursStrPre = `4 Day Work Week`;
			} else if (reduced_hour == "4-day-week-pro-rata") {
				reducedHoursStrPre = `Pro-rated 4 Day Work Week`;
			} else if (reduced_hour == "summer-fridays") {
				reducedHoursStrPost = ` at Companies with Summer Fridays`;
			} else if (reduced_hour == "half-day-fridays") {
				reducedHoursStrPost = ` with Half Day Fridays`;
			} else if (reduced_hour == "rotating-4-day-week") {
				reducedHoursStrPost = ` with a Rotating 4 Day Work Week`;
			} else if (reduced_hour == "flex-fridays") {
				reducedHoursStrPost = ` at Companies with Flex Fridays`;
			} else if (reduced_hour == "flexible-hours") {
				reducedHoursStrPost = ` at Companies with Fully Flexible Hours`;
			} else if (reduced_hour == "generous-pto") {
				reducedHoursStrPost = ` with Generous PTO`;
			} else if (reduced_hour == "unlimited-pto") {
				reducedHoursStrPost = ` with Unlimited PTO`;
			} else if (reduced_hour == "part-time") {
				reducedHoursStrPre = ` Part Time`;
			} else if (reduced_hour == "9-day-fortnight") {
				reducedHoursStrPost = ` with a 9/80 Schedule or 9 Day Fortnight`;
			} else if (reduced_hour.indexOf(",") > -1) {
				reducedHoursStrPost = ` with Reduced Working Hours`;
			} else {
				reducedHoursStrPre = `4 Day Work Week`;
			}
		} else {
			reducedHoursStrPre = `4 Day Work Week`;
		}

		// Level
		let levelStr = "";
		if (level) {
			levelStr = " " + ToTitleCase(level) + " ";
		}


		// Filter
		let filterStr = " ";
		if (filter) {
			//filterStr = " " + (RENAME_SKILLS[curParams.filter] ? RENAME_SKILLS[curParams.filter] : ToTitleCase(Unslug(curParams.filter))) + " ";

			if (jobs_filters && jobs_filters.items && jobs_filters.items[curParams.filter] && (jobs_filters.items[curParams.filter].label.indexOf("All ") != 0)) {
				filterStr = " " + jobs_filters.items[curParams.filter].label + " ";
			} else if (RENAME_SKILLS[curParams.filter]) {
				filterStr = " " + RENAME_SKILLS[curParams.filter] + " ";
			} else {
				filterStr = " " + ToTitleCase(Unslug(curParams.filter)) + " ";
			}

			if (!reduced_hour) {
				reducedHoursStrPre = `4 Day Work Week`;
			}
		}

		// Location
		var locationStr = "";
		if (location) {
			if (curParams.location == "usa" || curParams.location == "uk") {
				locationStr = " in the " + curParams.location.toUpperCase();
			} else if (curParams.location == "anywhere") {
				locationStr = "(work from anywhere)";
			} else {
				locationStr = " in " + ToTitleCase(Unslug(curParams.location));
			}
		}


		// Normal title
		let title = `${remoteStr} ${reducedHoursStrPre}${filterStr}${levelStr}Jobs${reducedHoursStrPost} ${locationStr}`;
		title = title.replace(/\s{2,}/g, ' ');

		if (!remoteStr && !reducedHoursStrPre && filterStr == " " && !reducedHoursStrPost && !locationStr) {
			title = "Jobs with a 4 Day Week & Reduced Working Hours";
		}

		title = title.trim();

		// Meta title
		var metaTitle = "" + title;

		if (metaTitle.length <= 39) {
			metaTitle += ` - Hiring now`
		}

		if (metaTitle.length <= 48) {
			metaTitle += ` | 4dayweek.io`
		}

		//	console.log("Meta title: ", metaTitle, metaTitle.length);

		// Description
		let description = "Find your next role at the #1 job board for jobs with a shorter work week e.g. 4 day week at full pay.";

		// Meta Description
		var metaDesc = "" + description;
		metaDesc += " Explore remote jobs at the most flexible companies.";


		// Other meta
		var metaImg = "https://media.fourdayweek.io/files/social-share.png";
		var metaUrl = ("https://4dayweek.io" + match.url).replace(/\/$/, "");


		// Cannonical
		let canonical = "";

		if (metaUrl == "https://4dayweek.io/jobs/4-day-week") {
			canonical = "https://4dayweek.io/";
		} else if (metaUrl == "https://4dayweek.io/jobs") {
			canonical = "https://4dayweek.io/";
		} else if (reduced_hour == "4-day-week") {
			canonical = metaUrl.replace("/4-day-week", "");
		}

		// Main content
		let endContent = "";
		if (data && data.loading && numJobs > Jobs.numJobsToLoad) {
			endContent = <div className="center"><Loader padded text="Fetching more jobs..." className="min-height" /></div>;
		} else if (!data.loading && data.itemIds && data.itemIds.length > 0 && hasFetchedAll) {
			endContent = <p className="no-jobs center">No more jobs to show...</p>;
		}



		return (
			<div className="main-container jobs-page">
				<HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} canonical={canonical} />
				<section className="row thin section-header">

					<SubscriberModalWrapper showForDelay={true} showForMouse={true} />


					<h1 className="smaller">{title}</h1>
					<div className="row">
						<h2 className="page-subtitle">{description}</h2>
					</div>
				</section>
				<section className="row no-padding">

					<div className="filters-wrapper">
						<div className="filters-content-wrapper">
							<JobFiltering page="list" />
						</div>
					</div>

					<div ref={this.jobsListRef} >
						<JobsList data={data} page={"jobs"} />
						{endContent}


						

					</div>

					{/*data && data.itemIds && data.itemIds.length > 0 ?
						<div className="row align-center margin-top"><button className="btn btn-huge btn-default" onClick={() => this.fetchMoreJobs()}>Fetch more jobs...</button></div> : ""*/}


				</section>


				<section className="row thin  margin-top jobs-bottom ">
					<SubscriberInlineWrapper className="subscriber-green" />
				</section>

			</div>
		);
	}
}



const mapStateToProps = (state) => {
	return {
		data: state.list.jobs_list,
		jobs_filters: state.list.jobs_filters,
		critique: state.item.critique,
		user: state.item.user,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({

	loadData: (params) => {
		dispatch(listActions.fetchItems("jobs", "list", params));
	},
	getCritique: (data) => {
		dispatch(itemActions.getItem("critiques", data, "critique"));
	},

	resetItems: () => {
		dispatch(listActions.resetItems(Jobs.page, Jobs.id));
	},


});

Jobs = connect(mapStateToProps, mapDispatchToProps)(Jobs);

export default withRouter(Jobs);