
import loadable from '@loadable/component';

// Main
import Companies from './pages/Companies';
import Company from './pages/Company';
import Countries from './pages/Countries';
import Country from './pages/Country';
import Home from './pages/Home';
import Job from './pages/Job';
import Jobs from './pages/Jobs';

import ProsAndCons from './pages/articles/ProsAndCons';


import Advice from './pages/articles/Advice';
import Advices from './pages/articles/Advices';

import EditJob from './pages/dashboard/EditJob';
import PostedJobs from './pages/dashboard/PostedJobs';
import Blog from './pages/articles/Blog';
import Blogs from './pages/articles/Blogs';
import CareerAdvice from './pages/articles/CareerAdvice';
import CareerAdvices from './pages/articles/CareerAdvices';
import CareerPath from './pages/articles/CareerPath';
import CareerPaths from './pages/articles/CareerPaths';
import CaseStudies from './pages/articles/CaseStudies';
import CaseStudy from './pages/articles/CaseStudy';
import Con from './pages/articles/Con';
import Industries from './pages/articles/Industries';
import Industry from './pages/articles/Industry';
import InterviewProcess from './pages/articles/InterviewProcess';
import InterviewProcesses from './pages/articles/InterviewProcesses';
import InterviewQuestion from './pages/articles/InterviewQuestion';
import InterviewQuestions from './pages/articles/InterviewQuestions';
import JobDescription from './pages/articles/JobDescription';
import Pro from './pages/articles/Pro';
import Region from './pages/articles/Region';
import Regions from './pages/articles/Regions';
import Salaries from './pages/articles/Salaries';
import Salary from './pages/articles/Salary';
import Schedule from './pages/articles/Schedule';
import Schedules from './pages/articles/Schedules';
import Studies from './pages/articles/Studies';
import Study from './pages/articles/Study';
import Template from './pages/articles/Template';
import Templates from './pages/articles/Templates';
import WorkLifeBalance from './pages/articles/WorkLifeBalance';
import WorkLifeBalances from './pages/articles/WorkLifeBalances';
import Logout from './pages/Logout';
import UserEdit from './pages/UserEdit';
import PostAJobCancelled from './pages/PostAJobCancelled';
import PostAJobSuccess from './pages/PostAJobSuccess';
import EditCompany from './pages/dashboard/EditCompany';
import CompanyBadge from './pages/dashboard/CompanyBadge';


const NotFound = loadable(() => import("./pages/NotFound"), { ssr: true });
const Contact = loadable(() => import("./pages/Contact"), { ssr: true });
const Privacy = loadable(() => import("./pages/Privacy"), { ssr: true });
const Terms = loadable(() => import("./pages/Terms"), { ssr: true });


const Sponsor = loadable(() => import("./pages/Sponsor"), { ssr: true });
const SponsorCancel = loadable(() => import("./pages/SponsorCancel"), { ssr: true });
const SponsorSuccess = loadable(() => import("./pages/SponsorSuccess"), { ssr: true });

const SignUp = loadable(() => import("./pages/SignUp"), { ssr: true });
const SignUpComplete = loadable(() => import("./pages/SignUpComplete"), { ssr: true });
const Login = loadable(() => import("./pages/Login"), { ssr: true });


const PostJob = loadable(() => import("./pages/PostJob"), { ssr: true });
const AddCompany = loadable(() => import("./pages/AddCompany"), { ssr: true });
const JobCategories = loadable(() => import("./pages/JobCategories"), { ssr: true });


export const RoutesLookup = [

  {
    path: '/not-found',
    component: NotFound
  },


  {
    path: '/add-company',
    component: AddCompany
  },

  {
    path: '/post-a-job/success',
    component: PostAJobSuccess
  },
  {
    path: '/post-a-job/cancelled',
    component: PostAJobCancelled
  },
  {
    path: '/post-a-job',
    component: PostJob
  },

  // User
  {
    path: '/profile/edit',
    component: UserEdit
  },


  {
    path: '/sign-up/complete',
    component: SignUpComplete
  },
  {
    path: '/sign-up',
    component: SignUp
  },

  {
    path: '/login',
    component: Login
  },
  {
    path: '/companies/:country/:filter2/:filter3/:filter4',
    component: Companies
  },
  {
    path: '/companies/:country/:filter2/:filter3',
    component: Companies
  },
  {
    path: '/companies/:country/:filter2',
    component: Companies
  },

  {
    path: '/companies/:country',
    component: Companies
  },
  {
    path: '/companies',
    component: Companies
  },
  {
    path: '/country/:slug',
    component: Country
  },
  {
    path: '/countries',
    component: Countries
  },
  {
    path: '/pros-and-cons',
    component: ProsAndCons
  },

  // Sponsor
  {
    path: '/sponsor/cancel',
    component: SponsorCancel
  },
  {
    path: '/sponsor/success',
    component: SponsorSuccess
  },
  {
    path: '/sponsor',
    component: Sponsor
  },

  // Job
  {
    path: '/remote-job/:url',
    component: Job
  },

  {
    path: '/job/:url',
    component: Job
  },


  // Company
  {
    path: '/company/:slug/jobs',
    component: Company
  },



  // Article
  {
    path: '/salary/:slug',
    component: Salary,
  },
  {
    path: '/salaries',
    component: Salaries
  },
  {
    path: '/study/:slug',
    component: Study,
  },
  {
    path: '/studies',
    component: Studies
  },

  {
    path: '/pro/:slug',
    component: Pro,
  },
  {
    path: '/con/:slug',
    component: Con,
  },
  {
    path: '/interview-process/:slug',
    component: InterviewProcess,
  },
  {
    path: '/interview-processes',
    component: InterviewProcesses
  },

  {
    path: '/job-description/:slug',
    component: JobDescription,
  },
  /* {
     path: '/job-descriptions',
     component: JobDescriptions
   },*/

  {
    path: '/career-path/:slug',
    component: CareerPath,
  },
  {
    path: '/career-paths',
    component: CareerPaths
  },

  {
    path: '/blog/:slug',
    component: Blog,
  },
  {
    path: '/blog',
    component: Blogs
  },


  {
    path: '/advice/:slug',
    component: Advice,
  },
  {
    path: '/advice',
    component: Advices
  },

  {
    path: '/work-life-balance/:slug',
    component: WorkLifeBalance,
  },
  {
    path: '/work-life-balance',
    component: WorkLifeBalances
  },

  {
    path: '/template/:slug',
    component: Template,
  },
  {
    path: '/templates',
    component: Templates
  },

  {
    path: '/industry/:slug',
    component: Industry,
  },
  {
    path: '/industries',
    component: Industries
  },

  {
    path: '/case-study/:slug',
    component: CaseStudy,
  },
  {
    path: '/case-studies',
    component: CaseStudies
  },

  {
    path: '/interview-questions/:slug',
    component: InterviewQuestion,
  },
  {
    path: '/interview-questions',
    component: InterviewQuestions
  },

  {
    path: '/schedule/:slug',
    component: Schedule,
  },
  {
    path: '/schedules',
    component: Schedules
  },


  {
    path: '/template/:slug',
    component: Template,
  },
  {
    path: '/templates',
    component: Templates
  },

  {
    path: '/career-advice/:slug',
    component: CareerAdvice,
  },
  {
    path: '/career-advice',
    component: CareerAdvices
  },

  {
    path: '/region/:slug',
    component: Region,
  },
  {
    path: '/regions',
    component: Regions
  },

  // Feature
  /*{
    path: '/feature',
    component: Feature
  },*/




  // Remote Jobs
  {
    path: '/remote-jobs/:filter/:filter2/:filter3',
    component: Jobs,
  },
  {
    path: '/remote-jobs/:filter/:filter2',
    component: Jobs,
  },
  {
    path: '/remote-jobs/:filter',
    component: Jobs,
  },
  {
    path: '/remote-jobs',
    component: Jobs,
  },

  // Hybrid jobs
  {
    path: '/hybrid-jobs/:filter/:filter2/:filter3',
    component: Jobs,
  },
  {
    path: '/hybrid-jobs/:filter/:filter2',
    component: Jobs,
  },
  {
    path: '/hybrid-jobs/:filter',
    component: Jobs,
  },
  {
    path: '/hybrid-jobs',
    component: Jobs,
  },

  // Onsite jobs
  {
    path: '/onsite-jobs/:filter/:filter2/:filter3',
    component: Jobs,
  },
  {
    path: '/onsite-jobs/:filter/:filter2',
    component: Jobs,
  },
  {
    path: '/onsite-jobs/:filter',
    component: Jobs,
  },
  {
    path: '/onsite-jobs',
    component: Jobs,
  },

  // Jobs
  {
    path: '/jobs/:filter/:filter2/:filter3',
    component: Jobs,
  },
  {
    path: '/jobs/:filter/:filter2',
    component: Jobs,
  },
  {
    path: '/jobs/:filter',
    component: Jobs,
  },
  {
    path: '/jobs',
    component: Jobs,
  },


  {
    path: '/job-categories',
    component: JobCategories,
  },

  // Dashboard

  {
    path: '/dashboard/jobs',
    component: PostedJobs,
  },

  {
    path: '/dashboard/job/edit/:id',
    component: EditJob,
  },

  {
    path: '/dashboard/company/widget',
    component: CompanyBadge,
  },




  {
    path: '/dashboard/company',
    component: EditCompany,
  },



  // Footer
  {
    path: '/terms',
    component: Terms
  },
  {
    path: '/contact',
    component: Contact
  },
  {
    path: '/privacy',
    component: Privacy
  },

  {
    path: '/logout',
    component: Logout
  },



  {
    path: '/',
    exact: true,
    component: Home
  },

  {
    component: NotFound
  },



];