import React from 'react';
import { Link } from 'react-router-dom';
import { CATEGORIES_GOOD_LOOKUP, REDUCED_HOURS_LOOKUP_ALT } from '../../utils/filters';
import { withRouter } from 'react-router-dom';

import { IsLoggedIn } from '../../utils/user';


class MenuDesktop extends React.Component {
    constructor() {
        super();
        this.menuWrapperRef = React.createRef();
        this.state = {
            expanded: {}
        };

    }

    onToggle(key) {
        var { expanded } = this.state;
        expanded[key] = !expanded[key];
        this.setState({ expanded });
    }

    onToggleSet(key, value) {
        var { expanded } = this.state;
        expanded[key] = value;
        this.setState({ expanded });
    }

    goToTop() {
        document.body.scrollTop = 0
    }


    render() {
        var path = this.props.location.pathname.trim()
        var spl = path.split("/");
        var page = spl[spl.length - 1];

        var { expanded } = this.state;
        let { user } = this.props;

        let jobsDropdown = <ul className="dropdown-menu" >
            {Object.keys(CATEGORIES_GOOD_LOOKUP).map((slug) => {
                return <li key={slug}><Link to={"/jobs/" + slug} onClick={() => this.goToTop()} >{CATEGORIES_GOOD_LOOKUP[slug]}</Link></li>
            })}

            <li className='seperator'><Link to="/jobs/4-day-week" onClick={() => this.goToTop()} onBlur={() => this.setState({ jobsCollapsed: true })}>4 Day Work Week</Link></li>
            <li className=''><Link to="/jobs/part-time" onClick={() => this.goToTop()} onBlur={() => this.setState({ jobsCollapsed: true })}>Part time</Link></li>

        </ul>

        let companiesDropdown = <ul className="dropdown-menu" >
            {Object.keys(REDUCED_HOURS_LOOKUP_ALT).map((slug) => {
                return <li key={slug}><Link to={"/companies/" + slug} onClick={() => this.goToTop()} >{REDUCED_HOURS_LOOKUP_ALT[slug]}</Link></li>
            })}

            <li className='seperator'><Link to="/companies" onClick={() => this.goToTop()} onBlur={() => this.setState({ jobsCollapsed: true })}>All companies</Link></li>

        </ul>


        let desktopMenu = [
            { title: "Jobs", url: "/jobs", dropdown: jobsDropdown },
            { title: "Companies", url: "/companies", dropdown: companiesDropdown },
            { title: "Pros and Cons", url: "/pros-and-cons", className: "hide-tablet" },
        ]

        let moreMenu = [
            { title: "Advice", url: "/advice" },
            { title: "Case studies", url: "/case-studies" },
            { title: "Countries", url: "/countries" },
            { title: "Pros and Cons", url: "/pros-and-cons", className: "hide-desktop" },
            { title: "Schedules", url: "/schedules" },
        ]

        // Logged in or not
        let rightMenu = "";

        if (IsLoggedIn(user)) {
            // Logged in 

            let userData = user && user.requested && !user.loading && user.response && !user.response.error ? user.response : null;


            let userDropdownItems = [{ title: "Edit profile", url: "/profile/edit", className: "btn btn-success btn-lg" }]


            if (userData && userData.company_id) {
                userDropdownItems.push({ title: "Edit company profile", url: "/dashboard/company", className: "btn btn-primary btn-lg", separator: true });
                userDropdownItems.push({ title: "View company score", url: "/dashboard/company/widget", className: "btn btn-primary btn-lg" });
            }


            userDropdownItems.push({ title: "Post a job", url: "/post-a-job", className: "btn btn-primary btn-lg" })

            if (userData.role == "company") {
                userDropdownItems.push({ title: "My jobs", url: "/dashboard/jobs", className: "btn btn-primary btn-lg" });
            }

            userDropdownItems.push({ title: "Logout", url: "/logout", className: "btn btn-ghost btn-lg", separator: true })


            rightMenu = <li className={"user-menu  " + (expanded["user"] ? "open" : "")} onMouseOver={() => this.onToggleSet("user", true)} onMouseOut={() => this.onToggleSet("user", false)} onFocus={() => this.onToggleSet("user", true)} >
                <Link to={"/profile/edit"} className="btn btn-ghost btn-lg" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">My Account</Link>

                <ul className="dropdown-menu" >
                    {userDropdownItems.map((item) => {
                        return <li key={item.title} className={item.separator ? 'separator' : ""}><Link to={item.url} className={"" + (page == item.url ? ' active' : "")}>{item.title}</Link></li>
                    })}

                </ul></li>;
        } else if (!user.loading) {
            // Logged out
            rightMenu = <>
                <li className='first-right-menu'><Link to="/post-a-job" className={"btn btn-success btn-lg cta " + (page == "post-a-job" ? ' active' : "")}>Post a job</Link></li>
                {/*   <li className='logged-out'><Link to="/add-company" className={"btn btn-light btn-lg " + (page == "add-company" ? ' active' : "")}>Add a company</Link></li> */}
                <li><Link to="/login" className={"btn btn-light btn-lg cta " + (page == "login" ? ' active' : "")}>Login</Link></li>
                <li><Link to="/sign-up" className={"btn btn-ghost btn-lg cta " + (page == "login" ? ' active' : "")}>Sign up</Link></li>
            </>

        } else {
            // Loading
            rightMenu = <li className='user-loading prevent-jumping'>Loading...</li>
        }

        return (

            <ul className={"nav navbar-nav desktop-menu "} style={{ maxHeight: 52 }}>

                {desktopMenu.map((item) => {
                    let pPage = page.replace(/^\/+|\/+$/g, '');
                    let iUrl = item.url.replace(/^\/+|\/+$/g, '');
                    let extraClass = "";
                    if (pPage == iUrl) {
                        extraClass = " active"
                    }
                    if (item.dropdown) {
                        return <li key={item.title} className={"more-menu " + (expanded[item.title] ? "open" : "")} onMouseOver={() => this.onToggleSet(item.title, true)} onMouseOut={() => this.onToggleSet(item.title, false)} onFocus={() => this.onToggleSet(item.title, true)} >
                            <Link to={item.url} className={"btn btn-ghost btn-lg " + extraClass} data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{item.title}</Link>
                            {item.dropdown}
                        </li>
                    }
                    return <li key={item.title} className={" " + (item.className ? item.className : "")}><Link to={item.url} className={"btn btn-ghost btn-lg " + extraClass}>{item.title}</Link>{item.dropdown}</li>
                })}

                {<li className={"more-menu extra-menu " + (expanded["more"] ? " open " : "")} onMouseOver={() => this.onToggleSet("more", true)} onMouseOut={() => this.onToggleSet("more", false)} onFocus={() => this.onToggleSet("more", true)} >
                    <button className="btn btn-ghost btn-lg" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">More...</button>
                    <ul className="dropdown-menu" >
                        {moreMenu.map((item) => {
                            return <li key={item.title} className={item.className ? item.className : ""}><Link to={item.url} className={"" + (page == item.url ? ' active' : "")}>{item.title}</Link></li>
                        })}
                        <li key={"resume-builder"} className='separator'><a href="https://resume.io/?ref=9pmj2v" target='_blank' rel="noopener noreferrer nofollow">Create my resume...</a></li>
                        <li key={"resume-review"}><a href="https://4dayweekresumes.com/" target='_blank' rel="noopener noreferrer nofollow">Career services...</a></li>
                        <li key={"submit-company"} className='separator'><Link to="/add-company" className="">Submit a company</Link></li>



                    </ul>
                </li>}


                {rightMenu}


            </ul>


        );
    }
}

export default withRouter(MenuDesktop);




