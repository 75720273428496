import React from 'react';
import HelmetMeta from '../components/global/HelmetMeta';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import CompaniesList from '../components/list/CompaniesList';
import * as listActions from '../store/list/actions';
import * as listReducer from '../store/list/reducer';
import * as URLTools from './../utils/url';
import * as vars from '../store/vars';
import { ToTitleCase, Unslug } from '../utils/format';
import CompanyFilters from '../components/filters/CompanyFilters';
import SubscriberModalWrapper from '../components/subscriber-form/SubscriberModalWrapper';
import { COUNTRIES_LOOKUP, EXTENDED_LOCATIONS_LOOKUP, FOUR_DAY_OFF_LOOKUP, GenerateLookups, IS_HIRING_LOOKUP, LOCATIONS_LOOKUP, REDUCED_HOURS_LOOKUP } from '../utils/filters';

class Companies extends React.Component {


	static page = "companies"
	static id = "list_home"
	static params = {
		live: "true",
	}
	static lookups = GenerateLookups({
		reduced_hour: REDUCED_HOURS_LOOKUP,
		location: EXTENDED_LOCATIONS_LOOKUP,
		is_hiring: IS_HIRING_LOOKUP,
		four_day_off: FOUR_DAY_OFF_LOOKUP,
	});


	static loadDataSSR(url) {
	//	console.log("loadDataSSR");
		var params = URLTools.GetQueryParams(url, Companies.lookups);
		return listActions.fetchItemsSSR(Companies.page, Companies.id, params);
	}

	static parseDataSSR(data) {
	//	console.log("parseDataSSR")
		var s = vars.getDefaultState();
		s.list = listReducer.receiveListItems(Companies.page + "_" + Companies.id, s.list, {
			type: listActions.RECEIVE_LIST_ITEMS,
			page: Companies.page,
			id: Companies.id,
			//	params: Companies.params,
			response: data
		});
		return s;
	}
	/* SSR end */

	componentDidMount() {
		var { location } = this.props;
		var curLoc = location.pathname + location.search;

		if (!this.props.data.init || (this.props.data.init && location.pathname == '/companies' && this.props.data.lastRequest && this.props.data.lastRequest.page != "companies" && !this.props.data.lastRequest.params)) {
			var curParams = URLTools.GetQueryParams(curLoc, Companies.lookups)
			this.props.loadData(curParams);
		}
	}

	componentDidUpdate(prevProps, prevState) {

		let { location: lastLocation } = prevProps;
		let { location: nextLocation } = this.props;

		if (URLTools.HasPageChanged(lastLocation, nextLocation)) {
			this.props.resetItems();
			var curParams = URLTools.GetQueryParams(nextLocation.pathname + nextLocation.search, Companies.lookups)
			this.props.loadData(curParams);
		}
	}

	componentWillUnmount() {
		//	console.log("Unmounting...", this.props);
		let { history } = this.props;
		if (history.action == "PUSH" && (history.location.pathname.indexOf("/company/") == -1)) {
			//	console.log("Resetting items");
			this.props.resetItems();
		}
	}


	render() {
		var { location } = this.props;

		var curParams = URLTools.GetQueryParams(location.pathname + location.search, Companies.lookups)

		// Set title

		let reduced_hours = curParams.reduced_hour;

		let reducedHoursPre = "";
		let reducedHoursPost = "";
		if (!reduced_hours) {
			reducedHoursPre = "4 Day Week";
		} else if (reduced_hours == "4-day-week") {
			reducedHoursPre = `4 Day Work Week`;
		} else if (reduced_hours == "4-day-week-pro-rata") {
			reducedHoursPost = `with a Pro-rated 4 Day Work Week`;
		} else if (reduced_hours == "summer-fridays") {
			reducedHoursPost = `with Summer Fridays`;
		} else if (reduced_hours == "half-day-fridays") {
			reducedHoursPost = `with Half Day Fridays`;
		} else if (reduced_hours == "rotating-4-day-week") {
			reducedHoursPost = `with a Rotating 4 Day Work Week`;
		} else if (reduced_hours == "flex-fridays") {
			reducedHoursPost = `which offer Flex Fridays`;
		}else if (reduced_hours == "flexible-hours") {
			reducedHoursPost = `with Fully Flexible Hours`;
		} else if (reduced_hours == "generous-pto") {
			reducedHoursPost = `with Great PTO`;
		} else if (reduced_hours == "unlimited-pto") {
			reducedHoursPost = `with Unlimited PTO`;
		} else if (reduced_hours == "part-time") {
			reducedHoursPre = `Part time`;
		} else if (reduced_hours == "9-day-fortnight") {
			reducedHoursPost = ` with a 9 Day Fortnight`;
		} else if (reduced_hours.indexOf(",") > -1) {
			reducedHoursPost = `with Reduced Working Hours`;
		} else {
			reducedHoursPre = `4 Day Work Week`;
		}

		let locationStr = ""
		if (curParams.location) {
			if (curParams.location == "netherlands") {
				locationStr = ` in the Netherlands`;
			} else if (curParams.location == "usa" || curParams.location == "uk") {
				locationStr = ` in the ` + curParams.location.toUpperCase();
			} else {
				locationStr = ` in ` + ToTitleCase(Unslug(curParams.location));
			}
		}

		// Title
		let title = `${reducedHoursPre} Companies ${reducedHoursPost}${locationStr}`;

		title = title.trim();

		// Description
		let description = "Explore the #1 job board for companies with a shorter work week and improve your work-life balance today. Find a job at a flexible remote working company now.";

		// Meta title
		var now = new Date();
		var metaTitle = `${title} | 4dayweek.io`


		// Other meta
		var metaImg = "https://media.fourdayweek.io/files/social-share-companies.png";
		var metaUrl = ("https://4dayweek.io" + location.pathname + location.search).replace(/\/$/, "");

		// Cannonical
		let canonical = "";

		if (metaUrl == "https://4dayweek.io/companies/4-day-week") {
			canonical = "https://4dayweek.io/companies";
		} 


		return (
			<div className="main-container  companies-page">
				<HelmetMeta title={metaTitle} desc={description} img={metaImg} url={metaUrl} canonical={canonical}/>
				<section className="row thin">


					<SubscriberModalWrapper showForDelay={true} showForMouse={true} showForScroll={true} />

					<h1 className="smaller">{title}</h1>
					<h2 className="page-subtitle">{description}</h2>
				</section>

				<div className="filters-wrapper">
					<div className="filters-content-wrapper">
						<CompanyFilters page="list" />
					</div>
				</div>


				<section className="row no-padding-mobile thin  ">

					<CompaniesList {...this.props} />
				</section>

				<section className="row thin align-center margin-top">
					<h3>Would you like your company added?</h3>
					<Link to="/add-company" className="btn btn-success btn-huge margin-top">Add company for free...</Link>
				</section>

			</div>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		data: state.list.companies_list_home,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({

	loadData: (params) => {
		dispatch(listActions.fetchItems("companies", "list_home", params));
	},
	resetItems: () => {
		dispatch(listActions.resetItems(Companies.page, Companies.id));
	},


});

Companies = connect(mapStateToProps, mapDispatchToProps)(Companies);

export default withRouter(Companies);