import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft, faAngleUp, faBabyCarriage, faBan, faBars, faBrain, faBriefcase, faBuilding, faChalkboardTeacher, faChartLine, faChartPie, faCheck, faCheckCircle, faChevronCircleRight, faChevronDown, faChevronLeft, faChevronRight, faChild, faCircle, faClipboardCheck, faClock, faCoins, faExternalLinkAlt, faFillDrip, faGlobe, faHandHoldingUsd, faHeart, faHeartbeat, faHome, faLaptopCode, faLightbulb, faMapMarkedAlt, faMoneyBill, faMoneyBillAlt, faPlus, faRocket, faSearch, faSitemap, faStar, faSun, faTag, faTimes, faTooth, faUmbrellaBeach, faUserAlt, faUserCheck, faWheelchair, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import { faGoogle, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';

import React from 'react';
//import CookieConsent from 'react-cookie-consent-notification';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import Footer from './components/global/Footer';
import Menu from './components/global/Menu';
import ScrollToTop from './components/misc/ScrollToTop';
import { RoutesLookup } from './routes';
import * as listActions from './store/list/actions';
import * as itemActions from './store/item/actions';
import posthog from 'posthog-js'

import { NotificationContainer } from 'react-notifications';
import * as Fathom from 'fathom-client';



if (typeof window !== 'undefined' && !window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
	console.log("Setting up Posthog...")
	posthog.init('phc_MBMP1uJyBi489sVGqsa7cFAkf00955SM5QAiPqbwDxr', { api_host: "https://eu.i.posthog.com" })
}



library.add(faChevronLeft, faChevronRight, faStar, faCheckCircle, faBars, faCheck, faHeart, faSearch, faGlobe, faAngleUp, faAngleLeft, faChevronCircleRight, faLinkedin, faTwitter, faInstagram, faChevronDown, faHeartbeat, faClock, faBabyCarriage, faTooth, faSun, faMoneyBillAlt, faHandHoldingUsd, faRocket, faLightbulb, faFillDrip, faChartPie, faClipboardCheck, faStar, faTimes, faBan, faHome, faExternalLinkAlt, faTwitter, faGoogle, faCircle, faChartLine, faChild, faLaptopCode, faMoneyBill, faTag, faBriefcase, faSitemap, faPlus, faBuilding, faCoins, faUserAlt, faLaptopCode, faUserCheck, faMapMarkedAlt, faWheelchair, faChalkboardTeacher, faUmbrellaBeach, faBrain, faSignOutAlt)


class App extends React.Component {


	constructor(props) {
		super(props);

		this.handleScroll = this.handleScroll.bind(this)
		this.state = {
		};
	}

	initSource() {

		// Parse the query string from the URL
		const params = new URLSearchParams(window.location.search);

		const consider = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "ref"];

		for (var i = 0; i < consider.length; i++) {
			if ((typeof window !== "undefined") && !localStorage.getItem(consider[i])) {
				localStorage.setItem(consider[i], params.get(consider[i]));
			}
		}

		// URL without any query string parameters
		const url = window.location.href.split('?')[0];

		if ((typeof window !== "undefined") && !localStorage.getItem("ref_url")) {
			localStorage.setItem("ref_url", url);
		}
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll, true, this);
		this.initSource();
		this.props.getAdverts();
		this.props.getUser();

		Fathom.load('CEOTHRGI');

	}


	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll() {
		var { scrolled } = this.state;

		const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
		if ((!scrolled && winScroll > 100) || (scrolled && winScroll <= 10)) {
			this.setState({ scrolled: !scrolled });
		}

	}


	render() {

		let { user } = this.props;
		let { scrolled } = this.state;


	//	if(!user || user.loading || !user.requested){
	//		return <div></div>
	//	}

		return (
			<div className={"wrapper "} id="outer-wrapper">
				<NotificationContainer />
				{/*<CookieConsent bottomPosition={true} buttonText={'I agree'}> Please confirm your consent to the use of cookies. See our <Link to={'/privacy'} style={{ color: '#fff' }}>privacy</Link> and <Link to={'/cookies'} style={{ color: '#fff' }}>cookie</Link> policies.</CookieConsent>*/}

				<div className={"content-wrapper full-width " + (scrolled ? "scrolled" : "")} id="content-wrapper">
					<Menu user={user} />
					<div className="main-container-wrapper" name="">

						<ScrollToTop>
							<Switch>
								{RoutesLookup.map((r) => <Route path={r.path} component={r.component} exact={r.exact} />)};
							</Switch>
						</ScrollToTop>
					</div>
					<Footer />

				</div>

			</div>
		);
	}
};


const mapStateToProps = (state) => {
	return {
		user: state.item.user,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({

	getUser: () => {
		dispatch(itemActions.getItem("users", {}, "user"));
	},
	getAdverts: () => {
		dispatch(listActions.fetchItems("adverts", "list_home"));
	},

});

App = connect(mapStateToProps, mapDispatchToProps)(App);

export default withRouter(App);