import * as actions from './actions';

export function getDefaultListsState() {
	return {

		jobs_company: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},
		jobs_home: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},
		jobs_list: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},
		articles_list_advices: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},

		articles_list_salaries: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},

		articles_list_career_paths: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},


		articles_list_studies: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},


		articles_list_interview_processes: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},

		articles_list_job_descriptions: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},


		articles_list_blogs: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},

		articles_list_work_life_balances: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},

		articles_list_interview_questions: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},

		articles_list_case_studies: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},

		articles_list_schedules: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},

		articles_list_industries: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},

		articles_list_templates: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},

		articles_list_career_advices: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},

		articles_list_regions: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},



		articles_related: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},

		countries_list_companies: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}

		},

		templates_list: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}

		},

		sponsors_list: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},
		jobs_filters: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},
		jobs_featured: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},


		companies_list_home: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false
		},
		companies_list_admin: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false
		},
		countries_list: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false
		},

		footer_links: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false
		},
		adverts_list_home: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},
		adverts_list_admin: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},
		jobs_roles: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},		
		dashboard_jobs: {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: false,
			init: false,
			lastRequest: {}
		},
	};
}


// Request
const requestListItems = (id, state, action) => {
	var temp = Object.assign({}, state);

	if (temp[id]) {
		temp[id].loading = true
	} else {
		temp[id] = {
			items: {},
			itemIds: [],
			newItemIds: [],
			loading: true,
			init: false,
			lastRequest: {}
		}
	}


	return Object.assign({}, state, temp);
};

// Receive
export const receiveListItems = (id, state, action) => {
	var temp = {};
	const ids = action.response.map((s) => s.id_str);
	const allItems = action.response.reduce((items, item) => {
		items[item.id_str] = item;
		return items;
	}, {});

	var tempReq = Object.assign({}, action);
	delete tempReq['response'];

	if (action.params && action.params.offset && action.params.offset > 0) {
		// Append items and ItemIds rather than replace
		temp[id] = {
			items: Object.assign({}, state[id].items, allItems),
			itemIds: [...state[id].itemIds, ...ids],
			loading: false,
			init: true,
			lastRequest: tempReq
		};
	} else {
		temp[id] = {
			items: allItems,
			itemIds: ids,
			loading: false,
			init: true,
			lastRequest: tempReq
		};
	}
	return Object.assign({}, state, temp);
};

export const resetListItems = (id, state, action) => {
	var temp = {};
	temp[id] = {
		items: {},
		itemIds: [],
		newItemIds: [],
		loading: false,
		init: false
	}
	return Object.assign({}, state, temp);
};


const list = (state, action) => {

	if (typeof state !== 'undefined' && typeof state.id_str !== 'undefined' && state.id_str !== action.id_str) {
		console.warn('No id match', state.id_str, action.id_str);
		return Object.assign({}, state);
	}
	var id = action.page + "_" + action.id;

	if(action.save){
		id = action.save;
	}

	switch (action.type) {
		case actions.RESET_LIST_ITEMS:
			return resetListItems(id, state, action);

		case actions.REQUEST_LIST_ITEMS:
			return requestListItems(id, state, action);

		case actions.RECEIVE_LIST_ITEMS:
			return receiveListItems(id, state, action);

		default:
			return state ? state : getDefaultListsState();
	}
};

export default list;