import loadable from '@loadable/component';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import HelmetMeta from '../components/global/HelmetMeta';
import HomeCategories from '../components/home-page/HomeCategories';
import SubscriberModalWrapper from '../components/subscriber-form/SubscriberModalWrapper';
import * as listActions from '../store/list/actions';
import * as listReducer from '../store/list/reducer';
import * as vars from '../store/vars';

import JobFiltering from '../components/filters/JobFiltering';

import HomeFeaturedIn from '../components/home-page/HomeFeaturedIn';
import SubscriberInlineWrapper from '../components/subscriber-form/SubscriberInlineWrapper';
import { IsLoggedIn } from '../utils/user';
import posthog from 'posthog-js';

const JobsList = loadable(() => import("../components/list/JobsList"), { ssr: true });
const Faqs = loadable(() => import("../components/misc/Faqs"), { ssr: true });

class Home extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			params: Home.params,
			fetchedAll: false,
			numJobRequests: 1,
			variant: 'control'
		};
	}

	/* SSR start */
	static params = {
		live: "true",
		limit: 15,
		minimal_data: "true",
		is_four_day: "true"
	}
	static page = "jobs"
	static id = "home"

	static loadDataSSR(url) {
		return listActions.fetchItemsSSR(Home.page, Home.id, Home.params);
	}

	static parseDataSSR(data) {
		var s = vars.getDefaultState();
		s.list = listReducer.receiveListItems(Home.page + "_" + Home.id, s.list, {
			type: listActions.RECEIVE_LIST_ITEMS,
			page: Home.page,
			id: Home.id,
			params: Home.params,
			response: data
		});

		return s;
	}
	/* SSR end */

	componentDidMount() {
		if (IsLoggedIn(this.props.user)) {
			//	this.props.history.push("/jobs");
			//	return;
		}


		var { data } = this.props;


		if (!data.init) {
			console.log("loading data")
			this.props.loadData(this.state.params);
		}

		posthog.onFeatureFlags(() => {
			const variant = posthog.getFeatureFlag('homepage_signup_form_ab_test');
			this.setState({ variant: variant || 'control' });
		  });
	}

	onSubscribeSubmit = () => {
		posthog.capture('homepage_signup_form_submitted');
		console.log("Home submitted");
	  };
	

	componentDidUpdate(prevProps, prevState) {
		if (IsLoggedIn(this.props.user)) {
			//		this.props.history.push("/jobs");
			//	return;
		}

	}

	renderVariant() {
		const { variant } = this.state;
		switch (variant) {
		  case 'variant_1':
			return (
			  <SubscriberInlineWrapper
				alwaysShow={true}
				h1="Find a 4 Day Week Job."
				description="Work less, earn the same. Enjoy a better work-life balance 🧘‍♂️"
				cta="Sign up for FREE..."
				onSubmit={this.onSubscribeSubmit}
			  />
			);
		  case 'variant_2':
			return (
			  <SubscriberInlineWrapper
				alwaysShow={true}
				h1="Find a 4 Day Week Job."
				description="Work less, earn the same. Enjoy a better work-life balance 🧘‍♂️"
				cta="Sign up for FREE..."
				onSubmit={this.onSubscribeSubmit}
			  />
			);
		  case 'control':
		  default:
			return (
			  <SubscriberInlineWrapper
				alwaysShow={true}
				h1="Find a 4 Day Week Job."
				description="Work less, earn the same. Enjoy a better work-life balance 🧘‍♂️"
				cta="Sign up for FREE..."
		

				onSubmit={this.onSubscribeSubmit}
			  />
			);
		}
	  }

	getMoreJobs(n = 0) {
		var { params, numJobRequests } = this.state;
		var { data } = this.props;
		var newLimit = params.limit + 25;
		//		this.props.loadData(ParseParams(params, newLimit))

		if (data.itemIds.length < params.limit) {
			this.setState({ params: params, fetchedAll: true })

		} else {
			params.limit = newLimit
			this.setState({ params: params, numJobRequests: numJobRequests + n })
		}
	}



	render() {
		var { data } = this.props;

		var metaTitle = `4 Day Work Week Jobs - 32 Hours @ 100% Pay | 4dayweek.io`
		var metaDesc = "Looking to upgrade your work-life balance? Then make sure your next job has a 4 day work week. Hiring remotely and worldwide now!";
		var metaImg = "https://media.fourdayweek.io/files/social-share.png";
		var metaUrl = "https://4dayweek.io/";

		var defaultValues = {
			reduced_hours: "4-day-week",
			location: "Anywhere"
		}


		return (
			<div className="main-container home-page">

				<HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} />

				<section className="row thinnest section-header">


					<SubscriberModalWrapper showForDelay={true} showForMouse={true} />
					<div className="row">
						{/*}
						<SubscriberInlineWrapper alwaysShow={true} h1="Find a 4 Day Week Job." description="Get weekly alerts for 4 day week jobs. That's 32hrs @ 100% pay 🧘‍♂️" cta="Sign up for free" onSubmit={() => console.log("Home submitted")} />*/}

						{this.renderVariant()}

					</div>


				</section>

				<HomeFeaturedIn />

				<section className="row section-padded " id="remote-jobs">
					<div className="filters-wrapper">
						<HomeCategories />

						<div className='filters-content-wrapper'>
							<h2 className="smaller">Want a better work-life balance?</h2>
							<h2 className="page-subtitle">We list <span className="emph">remote jobs</span> with a <span className="emph">4 day work week</span> (mostly 32hrs) with <span className="emph">no reduction in salary</span>.</h2>

							<JobFiltering defaultValues={defaultValues} page="home" />
						</div>
					</div>

					<div className='jobs-wrapper'>
						<JobsList page={"home"} data={data} headerTextMsg={<>Showing featured jobs <Link to="/jobs" className='btn btn-link margin-left'>Show all</Link></>} />
					</div>
					<div className="row align-center margin-top"><Link to="/jobs" className="btn btn-huge btn-success">View all 500+ four day week jobs...</Link></div>
				</section>


				<section className="row section-padded thin">
					<h3 className="section-title">FAQs</h3>
					<Faqs />
				</section>

				<section className="row thin margin-top ">
					<SubscriberInlineWrapper />
				</section>

			</div>
		);
	}
}



const mapStateToProps = (state) => {
	return {
		data: state.list.jobs_home,
		stats: state.item.get,
		user: state.item.user,
	};
};


const mapDispatchToProps = (dispatch, ownProps) => ({

	loadData: (params) => {
		dispatch(listActions.fetchItems(Home.page, Home.id, params));
	},

	resetItems: () => {
		dispatch(listActions.resetItems(Home.page, Home.id));
	},


});

Home = connect(mapStateToProps, mapDispatchToProps)(Home);

export default withRouter(Home);