
import React from 'react';
class JobResumeButton extends React.Component {

    render() {
        return (
            <a href="https://resume.io/?ref=9pmj2v" target='_blank' rel={"noopener noreferrer nofollow"} className='btn btn-ghost cta btn-lg'>Create free resume</a>
        );
    }
}

export default JobResumeButton;