import React from 'react';
import LabelField from '../fields/LabelField';

class ApplicationFormFields extends React.PureComponent {

    render() {

        var { job, form} = this.props;


        let fields = [];
        if (job.application_template && job.application_template.fields && job.application_template.fields.length > 0) {
            fields = job.application_template.fields.map((d) =>
                
                <LabelField key={d.id} inputType={d.type} id={d.id} label={d.label} inlineCol={true} name={d.id} singleCol={true} placeholder={d.placeholder} isRequired={d.required} />
            );
        }

        return (
            <>
           {/*     <LabelField key={"name"} inputType={"text"} id={"name"} label={"Full name"} inlineCol={true} name={"name"} singleCol={true} placeholder={"Enter your full name"} isRequired={true} />
                <LabelField key={"email"} inputType={"email"} id={"email"} label={"Email"} inlineCol={true} name={"email"} singleCol={true} placeholder={"Enter your email address"} isRequired={true} />
                <LabelField key={"website_url"} inputType={"url"} id={"website_url"} label={"LinkedIn / Portfolio / Github URL"} inlineCol={true} name={"website_url"} singleCol={true} isRequired={false} placeholder="Enter any relevant website you'd like to include"/>
                */}

                {job && job.apply_email ? <LabelField inputType="file" multiple={false} name="resume_upload"  id="resume_upload" label={"Resume / CV (PDF only)"} isRequired={true}  inlineCol={true} singleCol={true} onChange={form.mutators.changeResume} accepted={['application/pdf']}/> : ""}

                {job && job.apply_email ? 	<LabelField inputType="checkbox" id="get_resume_review" label={"Get a free resume review"} singleCol={true} isRequired={false} /> : ""}

                {fields}
              


            </>
        );
    }
}



export default ApplicationFormFields;