import React from 'react';
import LabelField from '../fields/LabelField';

class ApplicationFormQualifiers extends React.Component {

    render() {

        var { job } = this.props;

        if (!job || !job.application_template) return (<></>);

        let temp = job.application_template;
        if(!temp.boolean_qualifiers && !temp.select_qualifiers && !temp.num_qualifiers ) {
            return "";
        }


        // Boolean qualifiers
        let booleanQualifiers = job.application_template.boolean_qualifiers ? job.application_template.boolean_qualifiers.map((d) =>
            <LabelField key={d.id} inputType={"radio"} id={d.id} label={d.label} name={d.id} isRequired={true} options={
                [
                    { value: "Yes", label: 'Yes' },
                    { value: "No", label: 'No' },
                ]
            } inlineCol={true} singleCol={true}/>
        ) : "";


        // Number qualifiers
        let numQualifiers = job.application_template.num_qualifiers ? job.application_template.num_qualifiers.map((d) =>
            <LabelField key={d.id} inputType={"number"} id={d.id} label={d.label} name={d.id} placeholder={d.placeholder} isRequired={true} inlineCol={true} singleCol={true} />
        ) : "";



        // Select qualifiers
        let selectQualifiers = job.application_template.select_qualifiers ? job.application_template.select_qualifiers.map((d) =>
            <LabelField key={d.id} inputType={"select"} id={d.id} label={d.label} name={d.id} isRequired={true} options={d.options} inlineCol={true} singleCol={true} />
        ) : "";

        

        return (
            <div class="row margin-top">
                {booleanQualifiers}
                {numQualifiers}
                {selectQualifiers}
            </div>
        );
    }
}



export default ApplicationFormQualifiers;