import arrayMutators from 'final-form-arrays';
import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as itemActions from '../../store/item/actions';
import LabelField from '../fields/LabelField';
import Loader from '../../components/misc/Loader';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { OnChange } from 'react-final-form-listeners';
import handDrawnArrow from '../../assets/hand-drawn-arrow.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AddRef, CleanValues } from '../../utils/form';
import AuthCheckInbox from '../../components/auth/AuthCheckInbox';
import * as Fathom from 'fathom-client';
import posthog from 'posthog-js'

class SubscriberForm extends React.Component {

    constructor(props) {
        super(props);

        // Random 10 char id
        let formId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        this.state = {
            isSubmitted: false,
            isSparkloopPopupOpen: false,
            isResumeCollapsed: true,
            formId: formId,
            resumeUpload: null,

            // Global states
            isSubscriptionFormActive: false,
            hasSubscribed: false,
        }
    }

    // Submit
    onSubmit = (v) => {
      //  console.log("Submitting...", v)
        let { formId } = this.state;

        let values = Object.assign({}, v);

        values = CleanValues(values, formId);
        values = AddRef(values);

        // Subscribe
        this.props.authBegin(values);

        // State
        let newState = { isSubmitted: true, email: v.email, isSparkloopPopupOpen: true, isSubscriptionFormActive: true };
        if (values && values.get_resume_review && values.resume_upload && values.resume_upload.length) {
            newState.resumeUpload = v.resume_upload;
        }

        this.setState(newState);
        localStorage.setItem('has_subscribed', true);
        localStorage.setItem('is_subscription_form_active', true);

        // Logging
        Fathom.trackEvent('Subscribe');
        posthog.capture('Subscribe');

        if(this.props.onSubmit){
            this.props.onSubmit();
        }
    }

    // Is form active
    onFocus() {
      //  console.log("Form active")
        this.setState({ isSubscriptionFormActive: true });
        localStorage.setItem('is_subscription_form_active', true);
    }

    onBlur() {
       // console.log("Form inactive")
        this.setState({ isSubscriptionFormActive: false });
        localStorage.setItem('is_subscription_form_active', false);
    }

    // Close sparkloop popup
    componentDidMount() {
        document.addEventListener('click', this.handleClick);
        //    window.addEventListener('beforeunload', this.handleTabClose);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
        //  window.removeEventListener('beforeunload', this.handleTabClose);
    }


    handleClick = (event) => {
        // event.stopPropagation(); // Ensure other handlers are not stopped if needed

        if (event.target.classList.contains('sl-skip-link') || event.target.classList.contains('sl-button')) {
          //  console.log("Sparkloop popup closed")
            this.setState({ isSparkloopPopupOpen: false, isSubscriptionFormActive: false });

            localStorage.setItem('is_sparkloop_popup_open', false);
            localStorage.setItem('is_subscription_form_active', false);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { resumeUpload: newResumeUpload } = this.state;
        let { auth: newAuth, critique: newCritique } = this.props;
        let { auth: oldAuth, critique: oldCritique } = prevProps;

        if (oldAuth.loading && !newAuth.loading && newResumeUpload && newAuth.response && newAuth.response.data) {
            // Resume was uploaded, submit it to Writesea
            this.props.newCritique({
                email: newAuth.response.data.email,
                resume_upload: newResumeUpload,
            });
        } else if (oldCritique.loading && !newCritique.loading && newCritique.response && newCritique.response.data && !localStorage.getItem("critique_id")) {
            // Critique was uploaded, save the critique id
            localStorage.setItem("critique_id", newCritique.response.data.data.critiqueId);
        }
    }


    render() {
        var { companyName, autoFocus, cta, description, h1, h2 } = this.props;
        let { isSubmitted, formId, isResumeCollapsed, email, isSparkloopPopupOpen } = this.state;


        if (isSubmitted && !isSparkloopPopupOpen) {
      //      console.log("Showing auth check inbox")
            return (<AuthCheckInbox email={email} />)
        } else if (isSubmitted) {
            return <div className='subscriber-form-loading'>Loading... <Loader /></div>
        }

        var ctaBtn = cta ? cta : "Sign up for free";

        var desc = "Get weekly alerts for 4 day week jobs. That's 32hrs @ 100% pay 🧘‍♂️";
        if (description) {
            desc = description;
        } else if (companyName) {
            desc = "Get weekly alerts for jobs at " + companyName + " & other 4 day week (32hr) companies.";
        }

        let socials = ["temp2", "d_studzinski", "temp1", "rrmdp"];


        return (

            <>
                {h1 ? <h1 className="subscriber-h1">{h1}</h1> : ""}
                {h2 ? <h2 className="subscriber-h2">{h2}</h2> : ""}
                {!h1 & !h2 ? <h2 className="subscriber-h2">Would you like a 4 day work week?</h2> : ""}


                <Form
                    onSubmit={this.onSubmit}
                    mutators={{
                        ...arrayMutators,
                        changeResume: (args, state, utils) => {
                            utils.changeValue(state, 'resume_upload', () => args.length > 0 ? args[0] : [])
                        },
                    }}
                    render={({ handleSubmit, values, form, form: {
                        mutators: { push, pop }
                    } }) => (
                        <form onSubmit={handleSubmit}
                            className={"subscriber-form "}  >

                            <div className='row'>
                                <div className="subscriber-form-desc">{desc}</div>
                            </div>
                            <div className="row relative subscriber-email">
                                <LabelField inputType="email" id={"email"} singleCol={true} isRequired={true} hideErrors={true} isEmail={true} autoFocus={autoFocus} className="lg" placeholder={"Enter your email..."} onFocus={() => this.onFocus()} onBlur={() => this.onBlur()} />


                            </div>

                            <div className={'subscriber-resume row ' + (isResumeCollapsed ? "resume-collapsed" : "")}>
                                <LabelField inputType="checkbox" type="checkbox" className=" checkbox-left" id={"get_resume_review_" + formId} singleCol={true} label="Get a FREE resume review" onFocus={() => this.onFocus()} onBlur={() => this.onBlur()} />
                                {values["get_resume_review_" + formId] ?

                                    <LabelField
                                        inputType="file"
                                        multiple={false}
                                        id={"resume_upload_" + formId}
                                        isRequired={false}
                                        singleCol={true}
                                        onChange={form.mutators.changeResume}
                                        accepted={['application/pdf'/*, 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/rtf', 'application/vnd.oasis.opendocument.text'*/]}
                                        helper="Upload your resume (PDF)"
                                        icon={faFileUpload} />



                                    : ""}

                                <OnChange name={"get_resume_review_" + formId}>
                                    {(value, previous) => { this.setState({ isResumeCollapsed: !value }) }}
                                </OnChange>
                            </div>

                            <div className='row subscriber-cta'>
                                <button type="submit" className="btn btn-success cta btn-huge mobile-cta">{ctaBtn}</button>
                            </div>

                            <div className='row subscriber-social-proof'>

                                <div className='subscriber-social-proof-images'>
                                    {socials.map((social) => {
                                        return <img src={"/assets/social-images/" + social + ".jpg"} className='subscriber-social-proof-img' key={"img-" + social} alt={social} loading="lazy" />
                                    })}
                                </div>
                                <div className='subscriber-social-proof-text'>
                                    Join 400k job seekers getting weekly alerts...
                                </div>

                                <LazyLoadImage src={handDrawnArrow} className='subscriber-social-proof-arrow' alt="hand drawn arrow" />
                            </div>

                        </form>

                    )
                    }
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.item.auth,
        critique: state.item.critique,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({

    authBegin: (data) => {
        dispatch(itemActions.newItem("auth", data, "auth", "begin"));
    },

    newCritique: (data) => {
        dispatch(itemActions.newItem("critiques", data, "critique"));
    },

    newSubscriber: (data) => {
        dispatch(itemActions.newItem("subscriber", data));
    },



});

SubscriberForm = connect(mapStateToProps, mapDispatchToProps)(SubscriberForm);

export default withRouter(SubscriberForm);
